<template>
    <router-view class="full-height-layout" ref="routeview"></router-view>
</template>

<script>
export default {
    name: 'ProductContentCategoriesView',
    created () {
        this.$store.dispatch('productContent/getProductContentCategories')
    },
}
</script>
